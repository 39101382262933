<template>
  <div>
    <a
      class="btn btn-s btn-light-success btn-icon mr-2"
      @click.stop="delProduct(line.product)"
    >
      <i class="ki ki-minus icon-xs"></i>
    </a>

    <span class="font-weight-bold mr-2 text-dark-75 font-size-2 product-qty">
      {{ line.quantity }}
    </span>

    <a
      class="btn btn-s btn-light-success btn-icon"
      @click.stop="addProduct(line.product)"
    >
      <i class="ki ki-plus icon-xs"></i>
    </a>
  </div>
</template>

<script>
import { ADD_PRODUCT, DEL_PRODUCT } from "@/core/services/store/cart.module";
export default {
  name: "QuantitySelector",
  props: {
    line: Object
  },
  methods: {
    addProduct: function(product) {
      this.$store.dispatch(ADD_PRODUCT, product);
    },
    delProduct: function(product) {
      this.$store.dispatch(DEL_PRODUCT, product);
    }
  }
};
</script>

<style scoped>
.product-qty {
  padding-right: 6px;
  padding-left: 6px;
  font-size: 20px;
}
</style>
